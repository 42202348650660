.b2b-header{
    .img-header{
        max-width: 125px;
    }
}
.b2b-second{
    background-image: url(/images/about/12.jpg);
    height: 100%;
}
.b2b-table{
    img{
        width: 50px;
        height: auto;
        transition: transform .2s;
    }
    img:hover {
        transform: scale(4.5);
    }
}
.table-row{
    cursor:pointer;
    }
    
.boxDiscount{
    background-color: white;
    padding: 25px;
}
.tableListStyle{

table{
    tbody {
    counter-reset: rowNumber;
    }
    tbody tr::before {
    display: table-cell;
    counter-increment: rowNumber;
    content: counter(rowNumber) ".";
    padding-right: 0.3em;
    }
}
}
.tableCenter{
    td{
    text-align: center;
    vertical-align: middle;
    }
}