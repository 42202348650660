body{
    font-family: 'Titillium Web', sans-serif !important;
    a{
        text-decoration: none;
        color: black;
        transition: 0.3s;
        &:hover{
            text-decoration: none;
            color: black;
            opacity: 0.8;
            transition: 0.3s;
        }
    }
}
.table-hover:hover {background-color: #dddddd;}