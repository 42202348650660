
.contact-second{
    background-image: url(/images/about/12.jpg);
    height: 100%;
    .btn{
        background-color: lightgray;
        color: black;
        padding: 5px 15px;
        font-weight: 600;
        transition: 0.3s;
        border: 1px solid;
    }
    .btn:hover{
        transition: 0.3s;
        opacity: 0.8;
        color: #fff;
        background-color: gray;
    }
}