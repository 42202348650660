@media(max-width:765px){
    .box::before{
        content: '';
        position: absolute;
        width: 120px;
        height: 300%;
        background: linear-gradient(green, red);
        animation: animate 10s linear infinite;
        left: 175px;
        top: -175px;
    }
};
@media(max-width:576px){
    .box::before{
        content: '';
        position: absolute;
        width: 120px;
        height: 350%;
        background: linear-gradient(green, red);
        animation: animate 10s linear infinite;
        left: 220px;
        top: -220px;
    }
}
@media(max-width:500px){
    .box::before{
        content: '';
        position: absolute;
        width: 120px;
        height: 300%;
        background: linear-gradient(green, red);
        animation: animate 10s linear infinite;
        left: 175px;
        top: -175px;
    }
}

@media(max-width:450px){
    .box::before{
        content: '';
        position: absolute;
        width: 120px;
        height: 300%;
        background: linear-gradient(green, red);
        animation: animate 10s linear infinite;
        left: 150px;
        top: -200px;
    }
    .box_1::before{
        content: '';
        position: absolute;
        width: 120px;
        height: 300%;
        background: linear-gradient(green, red);
        animation: animate 10s linear infinite;
        left: 150px;
        top: -200px;
    }
}