.homepage-first{
    // background: url(/images/homepage/background.jpg);
    // height: 100%;
    background-size: cover;
    background-position: center;
    .videoBG {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        
    }
    .video-size{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }
    h1{
        font-size: 32px;
    }
}
.box,
.box_1{

    position: relative;
    background: rgba(129, 129, 129, 0.5);
    overflow: hidden;
    border-radius: 20px;
}
.box::before,
.box_1::before{
    content: '';
    position: absolute;
    width: 170px;
    height: 160%;
    background: linear-gradient(green, red);
    animation: animate 10s linear infinite;
    left: 75px;
    top: -75px;
}
.box::after,
.box_1::after{
    content: '';
    position: absolute;
    inset: 4px;
    background: whitesmoke;
    border-radius: 16px;
}
@keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.content{
    z-index: 10;
    position: relative;
}